import { watchImmediate } from '@vueuse/core'
import Clarity from '@microsoft/clarity'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { useProfileStore } from '~/stores/profile'

const setClarityTag = (key: string, value?: unknown) => {
  if (value) Clarity.setTag(key, value.toString())
}

export function useClaritySetup() {
  const { CLARITY } = useRuntimeConfig().public

  const { authUser } = storeToRefs(useAuthStore())
  const { currentlySelectedOrganisation } = storeToRefs(useProfileStore())

  watchImmediate(authUser, (authUserVal) => {
    if (!authUserVal) return
    Clarity.init(CLARITY)
    const { email, family_name, given_name } = authUserVal?.profile
    if (email) {
      const fullName = `${given_name} ${family_name}`
      Clarity.identify(email, '', '', fullName)
      setClarityTag('email', email)

      if (given_name && family_name) {
        setClarityTag('first name', given_name)
        setClarityTag('last name', family_name)
      }
    }
  })

  watchImmediate(
    currentlySelectedOrganisation,
    (orgVal) => {
      // Tag organisation info as soon as it's available
      if (orgVal) {
        const { trading_name, id, registered_name } = orgVal
        const companyName = trading_name || registered_name
        setClarityTag('company id', id)
        setClarityTag('company name', companyName)
      }
    },
    { deep: true },
  )
}
