import { watchImmediate } from '@vueuse/core'
import insp, { install } from 'inspectlet-es'
import { useAuthStore } from '~/stores/auth'

function tagSession(property: string | object) {
  insp(['tagSession', property])
}

export function useInspectlet() {
  const config = useRuntimeConfig()
  if (!config.public.INSPECTLET_ID) return
  const inspectletId = config.public.INSPECTLET_ID
  install(+inspectletId)

  const { personId, authUser } = storeToRefs(useAuthStore())
  const { currentlySelectedOrganisation } = storeToRefs(useProfileStore())

  watchImmediate(authUser, (newVal) => {
    if (!newVal) return
    const email = newVal.profile.email
    const given_name = newVal.profile.given_name
    const family_name = newVal.profile.family_name
    const user_name = `${given_name} ${family_name}`
    if (email) {
      insp(['identify', email])
      tagSession({
        email,
        user_name,
      })
    }
  })

  watchImmediate(
    [authUser, personId, currentlySelectedOrganisation] as const,
    ([authUserVal, personIdVal, orgVal]) => {
      if (!authUserVal) return

      // Tag session with personId as soon as it's available
      if (personIdVal) {
        tagSession({ personId: personIdVal })
      }

      // Tag organisation info as soon as it's available
      if (orgVal) {
        tagSession({
          organisation_id: orgVal.id || null,
          organisation_name: orgVal.trading_name || orgVal.registration_number,
        })
      }
    },
    { deep: true },
  )
}
