import type { GetMeResponseV2 } from '~/types/apiResponse/me.response'

export function initPendo(
  personId: string,
  org: GetMeResponseV2['organisations'][number],
  profile: GetMeResponseV2,
) {
  pendo.initialize({
    visitor: {
      id: personId,
      email: profile.work_email_address,
      full_name: `${profile.given_name} ${profile.family_name}`,
    },
    account: {
      id: org.trading_name || org.registered_name || org.id,
    },
  })
}
